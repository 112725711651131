import React, { FC } from 'react';
import { useSettings } from '@wix/tpa-settings/react';

import settingsParams from '../../../settingsParams';
import { classes } from './joke.st.css';
import CopyButton from '../copy-button/copy-button';

interface JokeProps {
  joke: string;
}

const Joke: FC<JokeProps> = (props) => {
  const settings = useSettings();

  const clipboardCopyEnabled = settings.get(
    settingsParams.copyToClipboardToggle,
  );

  return (
    <div className={classes.jokeWrapper}>
      {props.joke}{' '}
      {clipboardCopyEnabled ? <CopyButton text={props.joke} /> : null}
    </div>
  );
};

export default Joke;
