import { DAD_JOKES, UNIVERSAL_JOKES } from './endpoints';
import { buildUrlQuery } from './helpers';
import {
  DadJokeSearchParams,
  DadJokesApiResponse,
  UniversalJokesApiResponse,
} from './types';
import { HttpClient } from '@wix/yoshi-flow-editor';

export const client = new HttpClient();

export async function fetchDadJokes(queryParams: DadJokeSearchParams = {}) {
  if (!queryParams.page) {
    queryParams.page = Math.floor(Math.random() * 10) + 1;
  }

  const url = buildUrlQuery(DAD_JOKES, queryParams);

  const response = await client.get<DadJokesApiResponse>(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  return response.data;
}

export async function fetchUniversalJokes() {
  const response = await client.get<UniversalJokesApiResponse>(
    UNIVERSAL_JOKES,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  const normalizedOutput = {
    results: response.data.map((joke) => {
      return {
        id: joke.id.toString(),
        joke: joke.setup + ' ' + joke.punchline,
      };
    }),
  };

  return normalizedOutput;
}
