import React, { FC, ReactNode } from 'react';
import { classes } from './header.st.css';

interface HeaderProps {
  children: ReactNode;
  dataHook?: string;
}

const Header: FC<HeaderProps> = (props) => {
  return (
    <div className={classes.header}>
      <h2 data-hook={props.dataHook}>{props.children}</h2>
    </div>
  );
};

export default Header;
