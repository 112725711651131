import React, { FC, useState } from 'react';
import { TextButton } from 'wix-ui-tpa';
import { Duplicate } from '@wix/wix-base-ui/icons';
import { classes } from './copy-button.st.css';

interface CopyButtonProps {
  text: string;
}

const CopyButton: FC<CopyButtonProps> = ({ text }) => {
  const [isTextCopied, setCopiedState] = useState(false);
  const [copiedResetTimeout, setCopiedResetTimeout] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();

  async function copyToClipboard() {
    await navigator.clipboard.writeText(text);
    onTextCopy();
  }

  function onTextCopy() {
    clearTimeout(copiedResetTimeout);
    setCopiedState(true);

    setCopiedResetTimeout(
      setTimeout(() => {
        setCopiedState(false);
      }, 3000),
    );
  }

  return (
    <TextButton
      className={classes.copyButton}
      prefixIcon={<Duplicate />}
      onClick={copyToClipboard}
    >
      {isTextCopied ? 'Copied!' : 'Copy'}
    </TextButton>
  );
};

export default CopyButton;
