import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import applicationJson from '../../../.application.json';

export type ISettingsParams = {
  jokesOnPage: SettingsParamType.Number;
  greetingsText: SettingsParamType.Text;
  copyToClipboardToggle: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  jokesOnPage: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 20,
  },
  greetingsText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      `${t('app.settings.defaults.greetingsPretext')} ${
        applicationJson.appName
      }`,
  },
  copyToClipboardToggle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
});
