import React, { FC } from 'react';
import {
  useTranslation,
  WidgetProps,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import settingsParams from '../settingsParams';
import Header from './components/header/header';
import JokesDisplay from './components/jokes-display/jokes-display';
import { ConductedExperiments } from '../../../experiments/constants';

export type ControllerProps = {
  // Here, props passed via controller's `setProps` should be defined.
};

const Widget: FC<WidgetProps<ControllerProps>> = () => {
  const settings = useSettings();
  const { t } = useTranslation();

  const { experiments } = useExperiments();

  const canCustomizeJokesCount = !experiments.enabled(
    ConductedExperiments.UniversalJokes,
  );

  return (
    <div>
      <Header dataHook="app-title">
        {t('app.widget.welcome')} {settings.get(settingsParams.greetingsText)}!
      </Header>

      <JokesDisplay
        jokesOnPage={
          canCustomizeJokesCount
            ? settings.get(settingsParams.jokesOnPage)
            : undefined
        }
      />
    </div>
  );
};

export default Widget;
