import React, { FC, useEffect, useState } from 'react';
import { useExperiments } from '@wix/yoshi-flow-editor';

import { fetchDadJokes, fetchUniversalJokes } from '../../../../../api';
import { JokeData } from '../../../../../api/types';
import Joke from '../joke/joke';
import { classes } from './jokes-display.st.css';
import { ConductedExperiments } from '../../../../../experiments/constants';

interface JokesDisplayProps {
  jokesOnPage?: number;
}

const JokesDisplay: FC<JokesDisplayProps> = ({ jokesOnPage }) => {
  const [jokes, setJokes] = useState<JokeData[] | null>([]);

  const { experiments } = useExperiments();

  const shouldFetchDadJokes = !experiments.enabled(
    ConductedExperiments.UniversalJokes,
  );

  useEffect(() => {
    const fetchMethod = shouldFetchDadJokes
      ? () => {
          return fetchDadJokes({
            limit: jokesOnPage || 20,
          });
        }
      : fetchUniversalJokes;

    const fetchJokes = async () => {
      try {
        const fetchedJokes = await fetchMethod();
        setJokes(fetchedJokes.results);
      } catch {
        setJokes(null);
      }
    };

    fetchJokes();
  }, [jokesOnPage, shouldFetchDadJokes]);

  return (
    <div className={classes.jokesContainer}>
      {jokes
        ? jokes.map((jokeData) => (
            <Joke key={jokeData.id} joke={jokeData.joke} />
          ))
        : 'Failed to fetch jokes...'}
    </div>
  );
};

export default JokesDisplay;
