export function buildUrlQuery<T extends {}>(url: string, queryParams: T) {
  const params = Object.keys(queryParams);

  if (!params.length) {
    return url;
  }

  const queries: string[] = [];

  params.forEach((key) => {
    queries.push(`${key}=${queryParams[key as keyof T]}`);
  });

  return `${url}?${queries.join('&')}`;
}
